@use "sass:color";

.mainTab {
  margin: 0;
  height: 100%;
  padding-bottom: 10px;
  box-sizing: border-box;
}
.mainTabTitle {
  border-bottom: none;
  li {
    font-weight: 500;
    color: #666666;
  }
}
.mainTab:global(.layui-tab-brief .layui-this) {
  color: #0b72d3;
  &::after {
    border-bottom-color: #0b72d3;
  }
}
.mainTabContent {
  padding: 0;
}

.container {
  padding: 12px 4px;
}

.table {
  width: 100%;
  table-layout: fixed;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}
.tableCell {
  padding: 6px;
}
.tableInCell {
  cursor: pointer;
  padding: 6px;
  color: #fff;
  background: #2b6fdd;
  word-break: break-all;
  &:hover {
    background: lighten(#2b6fdd, 12%);
  }
}
